.menuWrapper {
    position: relative;
}

.menuButton {
    background-color: #2A82B2;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuList {
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 5px;
    top: 40px;
    right: 0;
    width: 90px;
    font-size: 14px;
}

.menuListItem {
    padding: 3px;
    border-bottom: 1px solid #DEE6F4;
    border-radius: 5px;
}
