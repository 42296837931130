.messageBox_blue,
.messageBox_white {
    border-radius: 16px;
    padding: 6px 10px 8px 10px;
}

.messageBox_blue {
    background-color: #2A82B2;
    color: #FFFFFF;
}

.messageBox_white {
    color: #141C32;
    border: 2px solid #DEE6F4;
}