.wrapper {
  text-align: center;
}

.messages {
  min-height: 60vh;
}

.messageWrapper {
  margin: 0 auto;
  width: 50%;
  height: 70vh;
  box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.11);
  border: 2px solid #DEE6F4;
  border-radius: 16px;
  padding: 2px;
  overflow-y: scroll;
  position: relative;
}

.messageWrapper_fullscreen {
  width: 98%;
  margin: 0;
}

.messageBox_answer,
.messageBox_question {
  width: 100%;
  display: flex;
}

.messageBox_answer {
  justify-content: start;
}

.messageBox_question {
  justify-content: end;
}

.messageBox_answer > div, 
.messageBox_question > div {
  max-width: 60%;
}

.headerButtonWrapper {
  display: flex;
}

.headerButtonWrapper > button:first-child {
  margin-right: 8px;
}

.chatInputTextWrapper {
  width: 95%;
  display: flex;
  bottom: 10px;
  left: 10px;
  position: sticky;
  justify-content: space-between;
  align-items: center;
}

.chatInputFieldWrapper {
  border-radius: 16px;
  background-color: #EDEEF4;
  padding: 20px 18px;
  width: 80%;
  margin-right: 24px;
}

.chatInputText {
  color: #141C32;
  font-size: 16px;
  line-height: 25px;
  border: none;
  background: none;
  margin-left: 8px;
  width: 100%;
}

.chatInputText:focus {
  outline: none;
}

.chatInputText::placeholder{
  color: #9097AF;
}
