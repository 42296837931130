.customButton {
    background-color: #2A82B2;
    color: #FFFFFF;
    min-width: 130px;
    border-radius: 16px;
    padding: 16px 24px;
    line-height: 19px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.customButton_disabled {
    opacity: 0.5;
}
