.pageHeader {
    height: 15px;
    padding: 35px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.11);
}

.pageHeader_mobile {
    padding: 35px 10px;
}
