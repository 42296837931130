.loginModalWrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(158, 164, 175, 0.48);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginModal {
    width: 500px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 29px;
    padding: 40px 24px;
    margin: 160px auto;
}

.loginModalInput {
    background-color: #EDEEF4;
    border-radius: 16px;
    font-size: 16px;
    line-height: 19px;
    color: #141C32;
    padding: 22px 20px;
    border: none;
    width: -webkit-fill-available;
    width: -moz-available;  
    width: fill-available;
}

.loginModalButton {
    background-color: #2A82B2;
    color: #FFFFFF;
    width: 130px;
    border-radius: 16px;
    padding: 16px 24px;
    line-height: 19px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    display: flex;
    justify-content: center;
}

.loginModalButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.loginModalInputErrorMessage {
    color: #FF9292;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin-top: 8px;
}
